import {
  CircleHelp,
  Wand2,
  LoaderCircle,
  Zap,
  Lightbulb,
  Hourglass,
  BadgeCheck,
  RefreshCcw,
  CircleDollarSign,
  Check,
  Ellipsis,
  CornerDownRight,
  ChevronDown,
  Pencil,
  Blocks,
  Sparkles,
  Mail,
  Calendar,
  Linkedin,
} from 'lucide-react';

export const ICONS_MAP = {
  zap: Zap,
  circleHelp: CircleHelp,
  loaderCircle: LoaderCircle,
  lightbulb: Lightbulb,
  hourGlass: Hourglass,
  badgeCheck: BadgeCheck,
  refresh: RefreshCcw,
  circleDollarSign: CircleDollarSign,
  check: Check,
  ellipsis: Ellipsis,
  cornerDownRight: CornerDownRight,
  chevronDown: ChevronDown,
  pencil: Pencil,
  wand: Wand2,
  blocks: Blocks,
  sparkles: Sparkles,
  mail: Mail,
  calendar: Calendar,
  linkedin: Linkedin,
};
