import { useCallback, MouseEvent, useMemo } from 'react';
import clsx from 'clsx';
import { AppLink } from '~atoms/AppLink/AppLink';
import { Icon } from '~atoms/Icon/Icon';
import { Loader } from '~atoms/Loader/Loader';
import { Typography } from '~atoms/Typography/Typography';
import { DEFAULT_BUTTON_ICON_SIZE } from './Button.const';
import s from './Button.module.scss';
import { ButtonTypes, ButtonProps } from './Button.type';

export const Button = <Type extends ButtonTypes>({
  text,
  type = 'button',
  variant = 'primary',
  size = 'large',
  width,
  isDisabled = false,
  isFullWidth = true,
  isLoading = false,
  isStopPropagation = false,
  action,
  leftIcon,
  rightIcon,
  iconSize,
  iconStrokeWidth,
  iconColor,
  className,
}: ButtonProps<Type>) => {
  const isNoText = !text;
  const commonProps = {
    // TODO: add here also data-testid in future to make testing easier
    'aria-label': text,
    className: clsx(
      s.element,
      s[variant],
      s[size],
      isNoText && s.noText,
      isFullWidth && s.fullWidth,
      (isDisabled || isLoading) && s.disabled,
      className,
    ),
  };

  if (width) {
    (commonProps as any).style = { width };
  }

  const commonIconProps = useMemo(
    () => ({
      size: iconSize ?? DEFAULT_BUTTON_ICON_SIZE,
      strokeWidth: iconStrokeWidth,
      color: iconColor,
    }),
    [iconSize, iconStrokeWidth, iconColor],
  );

  const handleButtonClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (isStopPropagation) {
      e.stopPropagation();
    }

    if (typeof action !== 'function') return null;

    action();
  };

  const renderContent = useCallback(
    () => (
      <div className={clsx(s.inner, isLoading && s.loading)}>
        {leftIcon && <Icon name={leftIcon} {...commonIconProps} />}

        {text && (
          <Typography
            as="span"
            styling={size === 'small' ? 'buttonSmall' : 'buttonLarge'}
          >
            {text}
          </Typography>
        )}

        {rightIcon && <Icon name={rightIcon} {...commonIconProps} />}

        {isLoading && <Loader color="background" size={18} />}
      </div>
    ),
    [leftIcon, rightIcon, text, size, commonIconProps, isLoading],
  );

  if (!action) {
    return (
      <button {...commonProps} type={type} disabled={isDisabled || isLoading}>
        {renderContent()}
      </button>
    );
  }

  if (typeof action === 'function') {
    return (
      <button
        {...commonProps}
        onClick={handleButtonClick}
        type={type}
        disabled={isDisabled || isLoading}
      >
        {renderContent()}
      </button>
    );
  }

  if (typeof action === 'object' && 'isExternal' in action) {
    const { isExternal, ...actionWithoutIsExternal } = action;

    return (
      <a
        {...commonProps}
        {...actionWithoutIsExternal}
        target="_blank"
        rel="noopener noreferrer"
      >
        {renderContent()}
      </a>
    );
  }

  return (
    <AppLink {...commonProps} {...action}>
      {renderContent()}
    </AppLink>
  );
};
