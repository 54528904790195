'use client';

import Lottie from 'lottie-react';
import heroAnimation from '~assets/video/heroAnimation.json';
import s from './HeroVideoAnimation.module.scss';

export const HeroVideoAnimation = () => {
  return (
    <div className={s.container}>
      <video src={'./demo.webm'} className={s.video} loop muted autoPlay />
      <div className={s.whiteBottomBar}></div>
      {/* <Lottie animationData={heroAnimation} className={s.video} loop autoPlay /> */}
    </div>
  );
};
