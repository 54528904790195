'use client';

import { useState } from 'react';
import clsx from 'clsx';
import {
  motion,
  AnimatePresence,
  useScroll,
  useMotionValueEvent,
} from 'framer-motion';
import { AppLink } from '~atoms/AppLink/AppLink';
import { Button } from '~atoms/Button/Button';
import { Logo } from '~atoms/Logo/Logo';
import { NAVBAR_LINKS } from '~constants/navbar';
import { CALENDLY_URL, SIGN_IN_URL, SIGN_UP_URL } from '~constants/url';
import { useTranslation } from '~i18n/hooks/useTranslation';
import s from './FloatingNavbar.module.scss';

export const FloatingNavbar = () => {
  const { scrollYProgress } = useScroll();
  const { t } = useTranslation();

  const [visible, setVisible] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  useMotionValueEvent(scrollYProgress, 'change', (current) => {
    if (typeof current === 'number') {
      let direction = current - scrollYProgress.getPrevious()!;

      if (direction < 0) {
        setVisible(true);
      } else {
        setVisible(false);
        if (isOpen) setIsOpen(false);
      }
    }
  });

  return (
    <AnimatePresence mode="wait">
      <motion.div
        animate={{
          y: visible ? 0 : -100,
          opacity: visible ? 1 : 0,
        }}
        transition={{
          duration: 0.2,
        }}
        className={clsx(s.container, isOpen && s.containerOpen)}
        onClick={() => {
          if (isOpen) {
            setIsOpen(false);
          }
        }}
      >
        <nav
          className={clsx(s.nav, isOpen && s.navOpen)}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={s.logoContainer}>
            <Logo />

            <div
              onClick={() => setIsOpen((prev) => !prev)}
              className={s.navToggle}
            >
              <div className={s.line} />
              <div className={s.line} />
              <div className={s.line} />
            </div>
          </div>

          <ul className={s.links}>
            {NAVBAR_LINKS.map((link) => (
              <li key={link.href}>
                <AppLink href={'/#' + link.href} color="gray1500">
                  {t(link.text)}
                </AppLink>
              </li>
            ))}
          </ul>

          <div className={s.actions}>
            <Button
              size="small"
              variant="secondary"
              text={t('navbar.signin')}
              action={{
                isExternal: true,
                href: SIGN_IN_URL,
              }}
            />

            <Button
              size="small"
              variant="primary"
              isFullWidth={false}
              action={{
                isExternal: true,
                href: CALENDLY_URL,
              }}
              text={t('hero.requestDemo')}
            />
          </div>
        </nav>
      </motion.div>
    </AnimatePresence>
  );
};
