'use client';

import dynamic from 'next/dynamic';
import integrationsAnimation from '~assets/video/integrationsAnimation.json';
import s from './IntegrationsVideoAnimation.module.scss';

// Dynamically import Lottie with SSR disabled
const Lottie = dynamic(() => import('lottie-react'), { ssr: false });

export const IntegrationsVideoAnimation = () => {
  return (
    <Lottie
      animationData={integrationsAnimation}
      className={s.video}
      loop
      autoPlay
    />
  );
};
